import React from 'react';
import Container from 'react-bootstrap/Container';

function PrivacyPage() {
  return (
    <div className="clients">
      <Container>
        <div>
          <p className="p-smallcontent" />
          <h1>Privacy beleid</h1>
          <br />

          <h4>Inleiding</h4>
          <p>
            { /* eslint-disable-next-line */}
Jongens van Nipkow Media neemt uw privacy zeer serieus en zal informatie over u (de betrokkene) op een veilige manier verwerken en gebruiken. In dit Privacy Statement leggen wij u uit welke gegevens wij verwerken, en met welk doel we dat doen. Daarnaast kunt u in dit Privacy Statement lezen over uw rechten met betrekking tot onze verwerking van uw persoonsgegevens. We raden u aan dit Privacy Statement aandachtig door te nemen. Voor vragen kunt u altijd contact opnemen met info@jongensvannipkow.nl.
            <br />
            <br />
            <br />
            <br />
          </p>
          <h4>Wie zijn Jongens van Nipkow Media?</h4>
          <p>
            {' '}
            { /* eslint-disable-next-line */}
Jongens van Nipkow Media, kantoorhoudende te Van Tijnerf 9, 3263 EL Oud-Beijerland, ingeschreven in het handelsregister van de Kamer van Koophandel onder nummer 68288662.
            <br />
            <br />
            {' '}
            { /* eslint-disable-next-line */}
Jongens van Nipkow Media is ten aanzien van de verwerking van uw persoonsgegevens door Jongens van Nipkow Media de verwerkingsverantwoordelijke.
            <br />
            <br />
            <br />
            <br />
          </p>
          <h4>Hoe gebruikt Jongens van Nipkow Media uw gegevens?</h4>
          <p>
            {' '}
            { /* eslint-disable-next-line */}
Hieronder vind u een overzicht van de doeleinden waarvoor Jongens van Nipkow Media persoonsgegevens over u verwerkt. Daarbij is telkens aangegeven welke gegevens Jongens van Nipkow Media voor dat specifieke doel gebruikt, wat de juridische grondslag is om die gegevens te mogen verwerken en hoe lang de gegevens door Jongens van Nipkow Media worden bewaard. Om het overzichtelijk te houden, hebben we alles gegroepeerd naar type gegevensstroom.
            <br />
            <br />
          </p>
          <h5>Dienstverlening, klantenbeheer en financiële administratie.</h5>
          <p>
            <font style={{ fontWeight: 'bold' }}>Doeleinde: Financiële administratie.</font>
            <br />
Gegevens: Bedrijfsnaam, Factuuradres, Bankgegevens (Naam bank/IBAN/BIC), Openstaande saldo.
            <br />
Grondslag: Wettelijke verplichting
            <br />
Bewaartermijn: Zo lang als noodzakelijk voor dit doeleinde.
            <br />
            <br />
            <font style={{ fontWeight: 'bold' }}>Doeleinde: Verlenen van de dienst zelf.</font>
            <br />
            {' '}
            { /* eslint-disable-next-line */}
Gegevens: NAW-gegevens, E-mailadres, Dossiergegevens, Financiële gegevens, Gegevens die nodig zijn om de dienst te leveren, Gegevens die tijdens de dienst gegenereerd worden.
            <br />
Grondslag: Uitvoering van de overeenkomst.
            <br />
Bewaartermijn: Zo lang als noodzakelijk voor dit doeleinde.
            <br />
            <br />
            <font style={{ fontWeight: 'bold' }}>Doeleinde: Facturatie.</font>
            <br />
            {' '}
            { /* eslint-disable-next-line */}
Gegevens: NAW-gegevens, E-mailadres, Dossiergegevens, Financiële gegevens, Gegevens die nodig zijn om de dienst te leveren, Gegevens die tijdens de dienst gegenereerd worden.
            <br />
Grondslag: Uitvoering van de overeenkomst.
            <br />
Bewaartermijn: Zo lang als noodzakelijk voor dit doeleinde.
            <br />
            <br />
            <font style={{ fontWeight: 'bold' }}>Doeleinde: CRM.</font>
            <br />
            {' '}
            { /* eslint-disable-next-line */}
Gegevens: Naam, Gebruiksnaam, E-mailadres, Locatie, Bestelgeschiedenis, Social media account, Telefoonnummer.
            <br />
Grondslag: Gerechtvaardigd belang.
            <br />
Betrokken belang: Commercieel belang.
            <br />
Bewaartermijn: Zo lang als noodzakelijk voor dit doeleinde.
            <br />
            <br />
            <font style={{ fontWeight: 'bold' }}>Doeleinde: Sollicitatie.</font>
            <br />
            {' '}
            { /* eslint-disable-next-line */}
Gegevens: Naam, Gebruiksnaam, E-mailadres, Locatie, Bestelgeschiedenis, Social media account, Telefoonnummer.
            <br />
Grondslag: Gerechtvaardigd belang.
            <br />
Betrokken belang: Commercieel belang.
            <br />
Bewaartermijn: Zo lang als noodzakelijk voor dit doeleinde.
            <br />
            <br />
          </p>
          <h5>Marketing</h5>
          <p>
            <font style={{ fontWeight: 'bold' }}>Doeleinde: Direct marketing.</font>
            <br />
Gegevens: Naam, NAW-gegevens, E-mailadres, Telefoonnummer, Klikgedrag.
            <br />
Grondslag: Gerechtvaardigd belang.
            <br />
Betrokken belang: Commercieel belang.
            <br />
Bewaartermijn: Zo lang als noodzakelijk voor dit doeleinde.
            <br />
            <br />
            <font style={{ fontWeight: 'bold' }}>Doeleinde: Nieuwsbrief.</font>
            <br />
Gegevens: Naam, NAW-gegevens, E-mailadres, Telefoonnummer, Klikgedrag.
            <br />
Grondslag: Gerechtvaardigd belang.
            <br />
Betrokken belang: Commercieel belang.
            <br />
Bewaartermijn: Zo lang als noodzakelijk voor dit doeleinde.
            <br />
            <br />
            <font style={{ fontWeight: 'bold' }}>Doeleinde: Social media marketing.</font>
            <br />
Gegevens: Naam, NAW-gegevens, E-mailadres, Telefoonnummer, Klikgedrag.
            <br />
Grondslag: Gerechtvaardigd belang.
            <br />
Betrokken belang: Commercieel belang.
            <br />
Bewaartermijn: Zo lang als noodzakelijk voor dit doeleinde.
            <br />
            <br />
            <font style={{ fontWeight: 'bold' }}>Doeleinde: Website analytics.</font>
            <br />
Gegevens: Surfgedrag, Locatie.
            <br />
Grondslag: Gerechtvaardigd belang.
            <br />
Betrokken belang: Commercieel belang.
            <br />
Bewaartermijn: Zo lang als noodzakelijk voor dit doeleinde.
            <br />
            <br />
            <font style={{ fontWeight: 'bold' }}>Doeleinde: Chatfunctie.</font>
            <br />
Gegevens: Inhoud van het bericht.
            <br />
Grondslag: Uitvoering van de overeenkomst.
            <br />
Bewaartermijn: Zo lang als noodzakelijk voor dit doeleinde.
            <br />
            <br />
            <br />
            <br />
          </p>
          <h4>Website</h4>
          <p>
            <br />
          </p>
          <h5>Hoe verkrijgen wij uw persoonsgegevens?</h5>
          <h5>
            <p>
              {' '}
              { /* eslint-disable-next-line */}
Jongens van Nipkow Media heeft gegevens over u in bezit, omdat u deze gegevens aan ons verstrekt hebt.
              <br />
              <br />
            </p>
          </h5>
          <h5>Wat zijn uw rechten?</h5>
          <p>
            {' '}
            { /* eslint-disable-next-line */}
Onder de Europese Algemene Verordening Gegevensbescherming beschikt u over een aantal rechten met betrekking tot uw gegevens en de verwerking ervan:
            <br />
            <br />
          </p>
          <h5>Inzage</h5>
          <h5>
            <p>
              {' '}
              { /* eslint-disable-next-line */}
U kunt uw eigen (persoons)gegevens altijd opvragen via info@jongensvannipkow.nl en bekijken en zo nodig aangeven om deze te laten wijzigen. Als u de persoonsgegevens wilt inzien die bij Jongens van Nipkow Media over u zijn vastgelegd, kunt u een verzoek tot inzage doen.
              <br />
              <br />
            </p>
          </h5>
          <h5>Wijzigen</h5>
          <p>
            {' '}
            { /* eslint-disable-next-line */}
Als u veranderingen wilt aanbrengen in de persoonsgegevens die u heeft gezien naar aanleiding van een inzageverzoek en die u niet zelf kunt veranderen, kunt u een verzoek hiertoe doen bij Jongens van Nipkow Media. U kunt verzoeken dat Jongens van Nipkow Media uw gegevens wijzigt, verbetert, aanvult of verwijdert
            <br />
            <br />
          </p>
          <h5>Beperken van de verwerking</h5>
          <p>
            {' '}
            { /* eslint-disable-next-line */}
U heeft daarnaast, onder voorwaarden, het recht om Jongens van Nipkow Media te verzoeken de verwerking van uw persoonsgegevens te beperken.
            <br />
            <br />
          </p>
          <h5>Recht van bezwaar</h5>
          <p>
            {' '}
            { /* eslint-disable-next-line */}
Als een bepaalde verwerking op grond van het ‘gerechtvaardigd belang’ van Jongens van Nipkow Media of een derde plaatsvindt, heeft u het recht om bezwaar te maken tegen die verwerking.
            <br />
            <br />
          </p>
          <h5>Gegevensoverdracht</h5>
          <p>
            {' '}
            { /* eslint-disable-next-line */}
U hebt het recht uw persoonsgegevens van Jongens van Nipkow Media te verkrijgen. Jongens van Nipkow Media zal deze verstrekken in een gestructureerde en gangbare vorm, die op eenvoudige wijze in andere gangbare digitale systemen te openen valt. Op deze manier kunt u uw gegevens ook bij een andere aanbieder onderbrengen.
            <br />
            <br />
          </p>
          <h5>Toestemming intrekken</h5>
          <p>
            {' '}
            { /* eslint-disable-next-line */}
In alle gevallen waarin de grondslag voor een bepaalde gegevensverwerking uw toestemming is, heeft u het recht die toestemming in te trekken. Dat heeft voor het verleden geen gevolgen, maar betekent wel dat wij die gegevens dan niet meer mogen verwerken. Het kan zijn dat Jongens van Nipkow Media u daardoor bepaalde diensten niet meer kunt leveren.
            <br />
            <br />
          </p>
          <h5>Reactie door Jongens van Nipkow Media</h5>
          <p>
            {' '}
            { /* eslint-disable-next-line */}
Een verzoek kan verstuurd worden naar info@jongensvannipkow.nl. Jongens van Nipkow Media zal uw verzoek zo snel mogelijk inwilligen en in elk geval niet later dan één (1) maand nadat Jongens van Nipkow Media een dergelijk verzoek heeft ontvangen. Indien Jongens van Nipkow Media uw verzoek afwijst zullen wij in ons antwoord aangeven waarom het verzoek wordt afgewezen.
            <br />
            <br />
          </p>
          <h5>Ontvangers van de persoonsgegevens</h5>
          <p>
            {' '}
            { /* eslint-disable-next-line */}
Het kan zijn dat Jongens van Nipkow Media verplicht is uw gegevens te verstrekken aan een derde partij, bijvoorbeeld op grond van een wettelijke verplichting.
            <br />
            <br />
          </p>
          <h5>Wat zijn cookies en hoe gebruikt Jongens van Nipkow Media ze?</h5>
          <p>
            {' '}
            { /* eslint-disable-next-line */}
Cookies zijn kleine stukjes (tekst)informatie die bij het bezoek van de website van Jongens van Nipkow Media worden meegestuurd aan uw browser en vervolgens op de harde schijf of in het geheugen van uw computer, tablet, mobiele telefoon of ander apparaat (hierna: “Apparaat”) worden opgeslagen. De cookies die via de website van Jongens van Nipkow Media worden geplaatst, kunnen uw Apparaat of de bestanden die daarop opgeslagen staan niet beschadigen. Als we het over ‘cookies’ hebben, bedoelen we niet alleen deze kleine stukjes (tekst)informatie, maar ook vergelijkbare technieken waarmee informatie verzameld wordt.
            <br />
            <br />
          </p>
          <h5>Kan dit Privacy Statement worden gewijzigd?</h5>
          <p>
            {' '}
            { /* eslint-disable-next-line */}
Dit Privacy Statement kan worden gewijzigd. Wij adviseren u daarom regelmatig het Privacy Statement na te lezen op eventuele wijzigingen.
            <br />
            <br />
          </p>
          <h5>Waar kunt u terecht met klachten en vragen?</h5>
          <p>
            {' '}
            { /* eslint-disable-next-line */}
 Mocht u nog vragen hebben over dit Privacy Statement en de wijze waarop Jongens van Nipkow Media uw gegevens gebruikt, dan kunt u een e-mail sturen naar info@jongensvannipkow.nl Ook als u een klacht heeft over de manier waarop Jongens van Nipkow Media uw gegevens verwerkt, kunt u een e-mail sturen naar info@jongensvannipkow.nl Daarnaast kunt u ook altijd contact opnemen met de bevoegde nationale toezichthouder op het gebied van privacybescherming. In Nederland is dit de Autoriteit Persoonsgegevens.
            <br />
            <br />
          </p>
        </div>
      </Container>

    </div>
  );
}


export default PrivacyPage;
