import React from 'react';
import {
  BrowserRouter as Router,
  Route,

} from 'react-router-dom';
import classNames from 'class-names';

import './App.scss';
import { useSelector } from 'react-redux';
import VideoJumbo from '../components/VideoJumbo/VideoJumbo';
import Header from '../components/Header/Header';
import WelcomeContainer from '../components/WelcomeContainer/WelcomeContainer';
import PortfolioContainer from '../components/Portfolio/PortfolioContainer/PortfolioContainer';
import ShowcaseModal from '../components/Showcase/ShowcaseModal/ShowcaseModal';
import MainMenu from '../components/MainMenu/MainMenu';
import Clients from '../components/Clients/Clients';
import Contact from '../components/Contact/Contact';
import PrivacyPage from './PrivacyPolicyPage';
import DisclaimerPage from './DisclaimerPage';
import CookiePage from './CookiePage';
import ScrollToTop from '../components/ScrollToTop';
import VoorwaardenPage from './VoorwaardenPage';

function App() {
  const mainMenuOpen = useSelector((state) => state.mainMenuOpen);


  return (
    <div>
      <Router>
        <Header />
        <div className={classNames('jvn-site', { 'jvn-site--mainmenuopen': mainMenuOpen })} />
        <Route path={['/', '/showcase/:showcaseSlug']} exact>
          <VideoJumbo />
          <WelcomeContainer />
          <PortfolioContainer />
          <Clients />
          <Contact />
        </Route>

        <Route path="/showcase/:showcaseSlug">
          <ShowcaseModal />
        </Route>

        <Route path="/privacy">
          <PrivacyPage />
          <Contact />
          <ScrollToTop />
        </Route>

        <Route path="/disclaimer">
          <DisclaimerPage />
          <Contact />
          <ScrollToTop />
        </Route>

        <Route path="/cookies">
          <CookiePage />
          <Contact />
          <ScrollToTop />
        </Route>


        <Route path="/voorwaarden">
          <VoorwaardenPage />
          <Contact />
          <ScrollToTop />
        </Route>

      </Router>
      <MainMenu />
    </div>
  );
}

export default App;
