/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import Container from 'react-bootstrap/Container';

function VoorwaardenPage() {
  return (
    <div className="clients">
      <Container>
        <div>
          <p className="p-smallcontent">
            <h1>Algemene</h1>
            <h2>Voorwaarden</h2>
            <br />

Jongens van Nipkow Media gevestigd en kantoorhoudende te Van Tijnerf 9, 3263 EL Oud-Beijerland, Nederland hierna te noemen: Jongens van Nipkow Media.
            <br />
            <br />
            <h3>Artikel 1. Definities</h3>

1. In deze algemene voorwaarden wordt verstaan onder:
            <br />
Jongens van Nipkow Media : Jongens van Nipkow Media
            <br />
Opdrachtgever: de wederpartij van Jongens van Nipkow Media.
            <br />
            <br />
            <br />
            <h3>Artikel 2. Toepasselijkheid van deze voorwaarden</h3>

1. Deze voorwaarden gelden voor elke aanbieding en elke overeenkomst tussen Jongens van Nipkow Media en een opdrachtgever waarop Jongens van Nipkow Media deze voorwaarden van toepassing heeft verklaard, voor zover van deze voorwaarden niet door partijen uitdrukkelijk en schriftelijk is afgeweken.
            <br />
            <br />
2. De onderhavige voorwaarden zijn eveneens van toepassing op alle overeenkomsten met Jongens van Nipkow Media voor de uitvoering waarvan derden dienen te worden betrokken.
            <br />
            <br />
            <br />
            <h3>Artikel 3. Offertes</h3>

1. Al onze aanbiedingen zijn vrijblijvend, tenzij in de offerte een termijn voor aanvaarding is genoemd.
            <br />
            <br />
Indien er extra werkzaamheden hebben plaatsgevonden naar aanleiding van de offerte zoals het schrijven van een compleet of gedeeltelijk script of het bezoeken van meerdere locaties om de filmmogelijkheden te bekijken, meerdere correctierondes dan overeengekomen zijn of aankoop van meerdere beelden bij een beeldbank dan zijn dit kosten die buiten de vrijblijvendheid van de offerte vallen.
            <br />
            <br />
2. De door Jongens van Nipkow Media gemaakte offertes zijn vrijblijvend; zij zijn geldig gedurende 30 dagen, tenzij anders aangegeven. Jongens van Nipkow Media is slechts aan de offertes gebonden indien de aanvaarding hiervan door de wederpartij schriftelijk binnen 30 dagen worden bevestigd.
            <br />
            <br />
3. De prijzen in de genoemde offertes zijn exclusief BTW, tenzij anders aangegeven. Prijswijzigingen en foutief vermelde prijzen zijn altijd voorbehouden.
            <br />
            <br />
4. Jongens van Nipkow Media stelt zijn offertes samen op basis van een vooraf afgesproken uurtarief of inschatting van de benodigde werkuren voor projectvoorbereiding, opnames, design, creatief brainstormen, editing, materiaalgebruik en andere project gerelateerde zaken. Jongens van Nipkow Media bepaalt deze uren in alle redelijkheid. Toch kan het voorkomen dat een opdrachtgever gedurende de productie additionele wensen heeft, die nog niet in de offerte zijn opgenomen. Deze extra uren worden op basis van een vast uurtarief, bovenop het in de offerte afgesproken factuurbedrag, in rekening gebracht. Tenzij anders is overeengekomen.
            <br />
            <br />
            <br />
            <h3>Artikel 4. Uitvoering van de overeenkomst</h3>

1. Jongens van Nipkow Media zal de overeenkomst naar beste inzicht en vermogen en overeenkomstig de eisen van goed vakmanschap uitvoeren en op grond van de op dat moment bekende stand der wetenschap.
            <br />
            <br />
2. Indien en voor zover een goede uitvoering van de overeenkomst dit vereist, heeft Jongens van Nipkow Media het recht bepaalde werkzaamheden te laten verrichten door derden.
            <br />
            <br />
3. De opdrachtgever draagt er zorg voor dat alle gegevens, waarvan Jongens van Nipkow Media aangeeft dat deze noodzakelijk zijn of waarvan de opdrachtgever redelijkerwijs behoort te begrijpen dat deze noodzakelijk zijn voor het uitvoeren van de overeenkomst, tijdig aan Jongens van Nipkow Media worden verstrekt. Indien de voor de uitvoering van de overeenkomst benodigde gegevens niet tijdig aan Jongens van Nipkow Media zijn verstrekt, heeft Jongens van Nipkow Media het recht de uitvoering van de overeenkomst op te schorten en/of de uit de vertraging voortvloeiende extra kosten volgens de gebruikelijke tarieven aan de opdrachtgever in rekening te brengen.
            <br />
            <br />
4. Gebruiker is niet aansprakelijk voor schade, van welke aard ook, doordat Jongens van Nipkow Media is uit gegaan van door de opdrachtgever verstrekte onjuiste en/of onvolledige gegevens, tenzij deze onjuistheid of onvolledigheid voor haar kenbaar behoorde te zijn.
            <br />
            <br />
5. Indien is overeengekomen dat de overeenkomst in fasen zal worden uitgevoerd kan Jongens van Nipkow Media de uitvoering van die onderdelen die tot een volgende fase behoren opschorten tot dat de opdrachtgever de resultaten van de daaraan voorafgaande fase schriftelijk heeft goedgekeurd.
            <br />
            <br />
6. Jongens van Nipkow Media is niet aansprakelijk voor de kwaliteit van de door de opdrachtgever aangeleverde eigen media.
            <br />
            <br />
            <br />
            <h3>Artikel 5. Contractduur; uitvoeringstermijn</h3>

1. De overeenkomst wordt aangegaan voor onbepaalde tijd, tenzij partijen uitdrukkelijk en schriftelijk anders overeenkomen.
            <br />
            <br />
2. Is binnen de looptijd van de overeenkomst voor de voltooiing van bepaalde werkzaamheden een termijn overeengekomen, dan is dit nimmer een fatale termijn. Bij overschrijding van de uitvoeringstermijn dient de opdrachtgever Jongens van Nipkow Media derhalve schriftelijk in gebreke te stellen.
            <br />
            <br />
            <br />
            <h3>Artikel 6. Wijziging van de overeenkomst</h3>

1. Indien tijdens de uitvoering van de overeenkomst blijkt dat voor een behoorlijke uitvoering het noodzakelijk is om de te verrichten werkzaamheden te wijzigen of aan te vullen, zullen partijen tijdig en in onderling overleg de overeenkomst dienovereenkomstig aanpassen.
            <br />
            <br />
2. Indien partijen overeenkomen dat de overeenkomst wordt gewijzigd of aangevuld, kan het tijdstip van voltooiing van de uitvoering daardoor worden beïnvloed. Jongens van Nipkow Media zal de opdrachtgever zo spoedig mogelijk hiervan op de hoogte stellen.
            <br />
            <br />
3. Indien de wijziging of aanvulling op de overeenkomst financiële en/of kwalitatieve consequenties hebben, zal Jongens van Nipkow Media de opdrachtgever hierover van tevoren inlichten.
            <br />
            <br />
4. Indien een vast honorarium is overeengekomen zal Jongens van Nipkow Media daarbij aangeven in hoeverre de wijziging of aanvulling van de overeenkomst een overschrijding van dit honorarium tot gevolg heeft.
            <br />
            <br />
5. In afwijking van lid 3 zal Jongens van Nipkow Media geen meerkosten in rekening kunnen brengen indien de wijziging of aanvulling het gevolg is van omstandigheden die aan haar kunnen worden toegerekend.
            <br />
            <br />
            <br />
            <h3>Artikel 7. Geheimhouding</h3>

Beide partijen zijn verplicht tot geheimhouding van alle vertrouwelijke informatie die zij in het kader van hun overeenkomst van elkaar of uit andere bron hebben verkregen. Informatie geldt als vertrouwelijk als dit door de andere partij is medegedeeld of als dit voortvloeit uit de aard van de informatie.
            <br />
            <br />
            <br />
            <h3>Artikel 8. Intellectuele eigendom</h3>

1. Onverminderd het bepaalde in artikel 7 van deze voorwaarden behoudt Jongens van Nipkow Media zich de rechten en bevoegdheden voor die haar toekomen op grond van de Nederlandse Auteurswet.
            <br />
            <br />
2. De opdrachtgevers vrijwaren Jongens van Nipkow Media volledig tegen aanspraken van derden voor inbreuk op auteursrechten ten gevolge van door Jongens van Nipkow Media ten behoeve van opdrachtgevers te verrichten montages en designs en/of het dupliceren van opnames, foto’s en overige media welke zij hebben aangeleverd. Inbreuk op de auteursrechten is als misdrijf wereldwijd strafbaar gesteld.
            <br />
            <br />
Concepten, ideeën en voorstellen die voor een opdrachtgever worden ontwikkeld vallen onder het auteursrecht en blijven formeel en juridisch in bezit van Jongens van Nipkow Media. In het geval van inbreuk of misbruik van dit auteursrecht stelt Jongens van Nipkow Media de organisatie of persoon in kwestie aansprakelijk. Deze aansprakelijkheid heeft juridische consequenties. Op alle Jongens van Nipkow Media producties rust het auteursrecht. Een Jongens van Nipkow Media productie mag niet verveelvoudigd, gereproduceerd of bewerkt worden zonder toestemming van de maker, Jongens van Nipkow Media. Het is mogelijk auteursrechten af te kopen in overeenstemming met Jongens van Nipkow Media. De opdrachtgever heeft wel een onbeperkt wereldwijd gebruikersrecht van de productie tenzij anders overeengekomen.
            <br />
            <br />
3. Jongens van Nipkow Media behoudt tevens het recht de door de uitvoering van de werkzaamheden toegenomen kennis voor andere doeleinden te gebruiken, voor zover hierbij geen vertrouwelijke informatie ter kennis van derden wordt gebracht.
            <br />
            <br />
            <br />
            <h3>Artikel 9. Opzegging</h3>

Beide partijen kunnen te allen tijde de overeenkomst schriftelijk opzeggen. Partijen dienen in dat geval een opzegtermijn van tenminste 2 volle kalendermaanden in acht te nemen.
            <br />
            <br />
            <br />
            <h3>Artikel 10. Ontbinding van de overeenkomst</h3>

1. De vorderingen van Jongens van Nipkow Media op de opdrachtgever zijn onmiddellijk opeisbaar in de volgende gevallen: - na het sluiten van de overeenkomst aan Jongens van Nipkow Media ter kennis gekomen omstandigheden geven Jongens van Nipkow Media goede grond te vrezen dat de opdrachtgever niet aan zijn verplichtingen zal voldoen; - indien Jongens van Nipkow Media de opdrachtgever bij het sluiten van de overeenkomst gevraagd heeft zekerheid te stellen voor de nakoming en deze zekerheid uitblijft dan wel onvoldoende is.
            <br />
            <br />
2. In de genoemde gevallen is Jongens van Nipkow Media bevoegd de verdere uitvoering van de overeenkomst op te schorten, dan wel tot ontbinding van de overeenkomst over te gaan, één en ander onverminderd het recht van Jongens van Nipkow Media schadevergoeding te vorderen.
            <br />
            <br />
            <br />
            <h3>Artikel 11. Gebreken; klachttermijnen</h3>

1. Klachten over de verrichte werkzaamheden dienen door de opdrachtgever binnen 8 dagen na ontdekking na voltooiing van de betreffende werkzaamheden schriftelijk en aangetekend te worden gemeld aan Jongens van Nipkow Media zulks op straffe van verval van elke aanspraak.
            <br />
            <br />
2. Indien een klacht gegrond is, zal Jongens van Nipkow Media de werkzaamheden alsnog verrichten zoals overeengekomen, tenzij dit inmiddels voor de opdrachtgever aantoonbaar zinloos is geworden. Dit laatste dient door de opdrachtgever schriftelijk kenbaar te worden gemaakt.
            <br />
            <br />
3. Indien het alsnog verrichten van de overeengekomen dienstverlening niet meer mogelijk of zinvol is, zal Jongens van Nipkow Media slechts aansprakelijk tot het maximale bedrag wat is gefactureerd voor de opdracht althans dat gedeelte van de opdracht waarop de aansprakelijkheid betrekking heeft.
            <br />
            <br />
            <br />
            <h3>Artikel 12. Honorarium</h3>

1. Voor aanbiedingen en overeenkomsten waarin een vast honorarium wordt aangeboden of is overeengekomen gelden de leden 2, 5 en 6 van dit artikel. Indien geen vast honorarium wordt overeengekomen, gelden de leden 3 t/m 6 van dit artikel.
            <br />
            <br />
2. Partijen kunnen bij het tot stand komen van de overeenkomst een vast honorarium overeenkomen. Het vaste honorarium is exclusief BTW.
            <br />
            <br />
3. Indien geen vast honorarium wordt overeengekomen, zal het honorarium worden vastgesteld op grond van werkelijk bestede uren. Het honorarium wordt berekend volgens de gebruikelijke uurtarieven van Jongens van Nipkow Media, geldende voor de periode waarin de werkzaamheden worden verricht, tenzij een daarvan afwijkend uurtarief is overeengekomen.
            <br />
            <br />
4. Eventuele kostenramingen zijn exclusief BTW. (indien van toepassing)
            <br />
            <br />
5. Bij opdrachten met een looptijd van meer dan 1 maand zullen de verschuldigde kosten periodiek in rekening worden gebracht.
            <br />
            <br />
6. Indien Jongens van Nipkow Media met de opdrachtgever een vast honorarium of uurtarief overeenkomt, is Jongens van Nipkow Media niettemin gerechtigd tot verhoging van dit honorarium of tarief. Jongens van Nipkow Media mag prijsstijgingen doorberekenen, indien Jongens van Nipkow Media kan aantonen dat zich tussen het moment van aanbieding en levering significante prijswijzigingen hebben voorgedaan ten aanzien van bijv. lonen of prijsstijging van gebruikte en ingekochte media.
            <br />
            <br />
            <br />
            <h3>Artikel 13. Betaling</h3>

1. Betaling dient te geschieden in verschillende termijnen. 50% voor start productie, 25% voor oplevering, 25% 14 dagen na ontvangst eindfactuur tenzij anders overeengekomen in de definitieve bevestiging.
            <br />
            <br />
2. Na het verstrijken van 30 dagen na de factuurdatum is de opdrachtgever in verzuim; de opdrachtgever is vanaf het moment van in verzuim treden over het opeisbare bedrag een rente verschuldigd van 3% per maand, tenzij de wettelijke rente hoger is in welk geval de wettelijke rente geldt.
            <br />
            <br />
3. In geval van liquidatie, faillissement of surseance van betaling van de opdrachtgever zullen de vorderingen van Jongens van Nipkow Media en de verplichtingen van de opdrachtgever jegens Jongens van Nipkow Media onmiddellijk opeisbaar zijn.
            <br />
            <br />
4. Door de opdrachtgever gedane betalingen strekken steeds ter afdoening in de eerste plaats van alle verschuldigde rente en kosten, in de tweede plaats van opeisbare facturen die het langst open staan, zelfs al vermeldt de opdrachtgever, dat de voldoening betrekking heeft op een latere factuur.
            <br />
            <br />
            <br />
            <h3>Artikel 14. Incassokosten</h3>

1. Is de opdrachtgever in gebreke of in verzuim met het nakomen van één of meer van zijn verplichtingen, dan komen alle redelijke kosten ter verkrijging van voldoening buiten rechte voor rekening van opdrachtgever. In ieder geval is de opdrachtgever verschuldigd:
            <br />
            <br />
- over de eerste € 3.000,- 15%
            <br />
- over het meerdere tot € 7.000,- 10%
            <br />
- over het meerdere tot € 15.500,- 8%
            <br />
- over het meerdere tot € 70.000,- 5%
            <br />
- over het meerdere 3%
            <br />
            <br />
2. Indien Jongens van Nipkow Media aantoont hogere kosten te hebben gemaakt, welke redelijkerwijs noodzakelijk waren, komen ook deze voor vergoeding in aanmerking.
            <br />
            <br />
            <br />
            <h3>Artikel 15. Aansprakelijkheid</h3>

Indien Jongens van Nipkow Media aansprakelijk is, dan is die aansprakelijkheid als volgt begrensd:
            <br />
            <br />
1. De aansprakelijkheid van Jongens van Nipkow Media, voor zover deze door haar aansprakelijkheidsverzekering wordt gedekt, is beperkt tot het bedrag van de door de verzekeraar gedane uitkering.
            <br />
            <br />
2. Indien de verzekeraar in enig geval niet tot uitkering overgaat of schade niet door de verzekering wordt gedekt, is de aansprakelijkheid van Jongens van Nipkow Media beperkt tot tweemaal de factuurwaarde van de opdracht, althans dat gedeelte van de opdracht waarop de aansprakelijkheid betrekking heeft.
            <br />
            <br />
3. In afwijking van hetgeen hierboven in lid 2 van dit artikel is bepaald, wordt bij een opdracht met een langere looptijd dan zes maanden, de aansprakelijkheid verder beperkt tot het over de laatste drie maanden verschuldigde honorariumgedeelte.
            <br />
            <br />
4. De in deze voorwaarden opgenomen beperkingen van de aansprakelijkheid gelden niet indien de schade te wijten is aan opzet of grove schuld van Jongens van Nipkow Media of haar ondergeschikten.
            <br />
            <br />
5. Jongens van Nipkow Media is nimmer aansprakelijk voor gevolgschade.
            <br />
            <br />
6. Aansprakelijkheid voor schade wegens claims van derden wordt door ons niet aanvaard.
            <br />
            <br />
            <br />
            <h3>Artikel 16. Overmacht</h3>

1. Onder overmacht wordt in deze algemene voorwaarden verstaan naast hetgeen daaromtrent in de wet en jurisprudentie wordt begrepen, alle van buiten komende oorzaken, voorzien of niet-voorzien, waarop gebruiker geen invloed kan uitoefenen, doch waardoor gebruiker niet in staat is haar verplichtingen na te komen. Werkstakingen in het bedrijf van Jongens van Nipkow Media worden daaronder begrepen.
            <br />
            <br />
2. Jongens van Nipkow Media heeft ook het recht zich op overmacht te beroepen, indien de omstandigheid die (verdere) nakoming verhindert, intreedt nadat Jongens van Nipkow Media haar verbintenis had moeten nakomen.
            <br />
            <br />
3. Tijdens overmacht worden de verplichtingen van Jongens van Nipkow Media opgeschort. Indien de periode waarin door overmacht nakoming van de verplichtingen door Jongens van Nipkow Media niet mogelijk is langer duurt dan 2 maanden zijn beide partijen bevoegd de overeenkomst te ontbinden zonder dat er in dat geval een verplichting tot schadevergoeding bestaat.
            <br />
            <br />
4. Indien Jongens van Nipkow Media bij het intreden van de overmacht al gedeeltelijk aan haar verplichtingen heeft voldaan, of slechts gedeeltelijk aan haar verplichtingen kan voldoen is zij gerechtigd het reeds uitgevoerde c.q. uitvoerbare deel afzonderlijk te factureren en is de opdrachtgever gehouden deze factuur te voldoen als betrof het een het een afzonderlijk contract. Dit geldt echter niet als het reeds uitgevoerde c.q. uitvoerbare deel geen zelfstandige waarde heeft.
            <br />
            <br />
            <br />
            <h3>Artikel 17. Geschillenbeslechting</h3>

De rechter in het land van Jongens van Nipkow Media is bij uitsluiting bevoegd van geschillen kennis te nemen, tenzij de kantonrechter bevoegd is. Niettemin heeft Jongens van Nipkow Media het recht haar wederpartij te dagvaarden voor de volgens de wet bevoegde rechter.
            <br />
            <br />
            <br />
            <h3>Artikel 18. Reis en verblijfkosten</h3>

Alle reis- en verblijfskosten worden beschouwd als additionele kosten en worden niet meegenomen in de offerte. Deze worden apart toegevoegd aan de eindfactuur.
            <br />
            <br />
            <br />
            <h3>Artikel 19. Muziek en foto/video rechten</h3>

Voor muziek, foto’s en video’s die in een productie wordt gebruikt moeten rechten afgedragen worden. Alle door Jongens van Nipkow Media in de offerte aangeboden en aangekochte muziek, foto’s en video’s zijn inclusief afkoop van de muziekrechten tenzij anders vermeld.
            <br />
            <br />
            <br />
            <h3>Artikel 20. Voice-over</h3>

Bij sommige producties wordt gebruik gemaakt van een of meerdere voice-overs, oftewel ingesproken stemmen. De kosten van een voice-over vallen onder de additionele kosten en worden toegevoegd aan de eindfactuur. Tenzij anders is overeengekomen.
            <br />
            <br />
            <br />
            <h3>Artikel 21. Correcties en wijzigingen</h3>

Na oplevering van het project heeft u als opdrachtgever recht op correctie rondes (2) om wijzigingen door te voeren. Deze correcties vallen binnen de begroting. Na deze afgesproken rondes zullen wij de overige correcties uitvoeren voor een meerprijs van € 60,00 per uur. Jongens van Nipkow Media zal de opdrachtgever hiervan tussentijds op de hoogte stellen.
            <br />
            <br />
            <br />
            <h3>Artikel 22. Promotie</h3>

Jongens van Nipkow Media behoudt de mogelijkheid om materialen te gebruiken ter promotie.
            <br />
            <br />
            <br />
            <h3>Artikel 23. Toepasselijk recht</h3>

Op elke overeenkomst tussen Jongens van Nipkow Media en de opdrachtgever is Nederlands recht van toepassing.
            <br />
            <br />
            <br />
            <h3>Artikel 24. Bevoegde Rechter</h3>

Eventuele geschillen tussen opdrachtgever en Jongens van Nipkow Media, ten aanzien waarvan de rechtbank absoluut bevoegd is, zullen bij uitsluiting worden beslist door de Rechtbank te Alkmaar, onverminderd het recht van Jongens van Nipkow Media om de volgens de wet bevoegde rechtbank te adiëren.
            <br />
            <br />
            <br />
            <h3>Artikel 25. Wijziging en vindplaats van de voorwaarden</h3>

Deze voorwaarden zijn gedeponeerd op onze website. Van toepassing is steeds de laatst gedeponeerde versie c.q. de versie zoals geldend ten tijde van het tot stand komen van de onderhavige opdracht.
            {' '}

          </p>
        </div>
      </Container>

    </div>
  );
}


export default VoorwaardenPage;
