import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './PortfolioTile.scss';
import Col from 'react-bootstrap/Col';
import placeholder from './16-9-placeholder.png';

function PortfolioTile({
  poster, project, company, video, slug,
}) {
  const refPlayer = useRef(null);


  const handleMouseEnter = () => {
    if (refPlayer) {
      refPlayer.current.currentTime = 0;
      setTimeout(() => {
        refPlayer.current.play();
      }, 50);
    }
  };

  const handleMouseLeave = () => {
    if (refPlayer) {
      refPlayer.current.pause();
      setTimeout(() => {
        refPlayer.current.play();
      }, 250);
      refPlayer.current.currentTime = 0;
    }
  };


  return (
    <Col sm={6} lg={4} className="portfoliotile">
      <Link to={slug} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <img src={placeholder} alt="" className="portfoliotile__placeholder" />
        {video
          && (
          <video muted loop className="portfoliotile__video" ref={refPlayer}>
            <source src={video} type="video/mp4" />
          </video>
          )}
        <div className="portfoliotile__poster" style={{ backgroundImage: `url(${poster})` }} />

        <div className="portfoliotile__footer">
          <span className="portfoliotile__footer__company">{company}</span>
          <span className="portfoliotile__footer__project">{project}</span>
        </div>
      </Link>
    </Col>
  );
}

PortfolioTile.propTypes = {
  poster: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  video: PropTypes.string,
};

PortfolioTile.defaultProps = {
  video: '',
};

export default PortfolioTile;
