const initialState = {
  mainMenuOpen: false,
  headerIsFixed: true,
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case 'TOGGLE_MAIN_MENU':
      return { ...state, mainMenuOpen: !state.mainMenuOpen };
    case 'HEADER_SET_VISABILITY':
      return { ...state, headerIsFixed: !!action.status };
    default:
      return state;
  }
}

export default rootReducer;
