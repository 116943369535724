import React, { useEffect } from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import './VideoJumbo.scss';
import Container from 'react-bootstrap/Container';
import { useDispatch } from 'react-redux';
import scroll from './scroll.svg';


function VideoJumbo() {
  // eslint-disable jsx-a11y/media-has-caption
  const dispatch = useDispatch(); //eslint-disable-line
  let headerStatus = false;

  const handleWindowScroll = () => {
    if (!headerStatus && window.scrollY > 130) { //eslint-disable-line
      dispatch({ type: 'HEADER_SET_VISABILITY', status: true });
      headerStatus = true;
    } else
    if (headerStatus && window.scrollY < 130) { //eslint-disable-line
      dispatch({ type: 'HEADER_SET_VISABILITY', status: false });
      headerStatus = false;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll); //eslint-disable-line
    dispatch({ type: 'HEADER_SET_VISABILITY', status: false });
    return () => {
      dispatch({ type: 'HEADER_SET_VISABILITY', status: true });
      window.removeEventListener('scroll', handleWindowScroll); //eslint-disable-line
    };
  }, []);


  return (
    <Jumbotron className="videojumbo">
      <video autoPlay loop muted className="videojumbo__player">
        <source src="/jumbo.mp4" type="video/mp4" />
      </video>
      <div className="videojumbo__text">
        <Container>
          <h1>
Livestream &amp;
            {' '}
            <br />
visual content
          </h1>
        </Container>
      </div>
      <div className="videojumbo__footer">
        <img src={scroll} alt="" className="videojumbo__footer__scroll-icon" />
      </div>
    </Jumbotron>
  );
}

export default VideoJumbo;
