import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import './Clients.scss';
import Col from 'react-bootstrap/Col';

function Clients() {
  return (
    <div className="clients">
      <Container>
        <h1 className="">Trots</h1>
        <h2 className="mb-5">om te werken voor:</h2>
        <Row>
          <Col xs={3} sm={2} className="d-flex clients__logo justify-content-center align-items-center">
            <img src="/logos/logo-inholland.svg" alt="logo" className="clients__logo__img w-75 text-center" />
          </Col>
          <Col xs={3} sm={2} className="d-flex clients__logo justify-content-center align-items-center">
            <img src="/logos/logo-ohw.svg" alt="logo" className="clients__logo__img" />
          </Col>
          <Col xs={3} sm={2} className="d-flex clients__logo justify-content-center align-items-center">
            <img src="/logos/logo-gem-hw.svg" alt="logo" className="clients__logo__img" />
          </Col>
          <Col xs={3} sm={2} className="d-flex clients__logo justify-content-center align-items-center">
            <img src="logos/logo-wvo.svg" alt="logo" className="clients__logo__img" />
          </Col>
          <Col xs={3} sm={2} className="d-flex clients__logo justify-content-center align-items-center">
            <img src="logos/logo-konijnendijk.svg" alt="logo" className="clients__logo__img w-75" />
          </Col>
          <Col xs={3} sm={2} className="d-flex clients__logo justify-content-center align-items-center">
            <img src="logos/logo-smaakmakers.png" alt="logo" className="clients__logo__img w-100 " />
          </Col>


          <Col xs={3} sm={2} className="d-flex clients__logo justify-content-center align-items-center">
            <img src="/logos/logo-tsn.svg" alt="logo" className="clients__logo__img" />
          </Col>
          <Col xs={3} sm={2} className="d-flex clients__logo justify-content-center align-items-center">
            <img src="/logos/logo-robeco.svg" alt="logo" className="clients__logo__img" />
          </Col>
          <Col xs={3} sm={2} className="d-flex clients__logo justify-content-center align-items-center">
            <img src="/logos/logo-kwakernaat.svg" alt="logo" className="clients__logo__img" />

          </Col>
          <Col xs={3} sm={2} className="d-flex clients__logo justify-content-center align-items-center">
            <img src="/logos/logo-hw.svg" alt="logo" className="clients__logo__img" />
          </Col>
          <Col xs={3} sm={2} className="d-flex clients__logo justify-content-center align-items-center">
            <img src="/logos/logo_minvocew.svg" alt="logo" className="clients__logo__img" />

          </Col>
          <Col xs={3} sm={2} className="d-flex clients__logo justify-content-center align-items-center">
            <img src="/logos/logo-hhr.svg" alt="logo" className="clients__logo__img" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Clients;
