import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import classNames from 'class-names';
import { useParams, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import './ShowcaseModal.scss';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import placeholder from './16-9-placeholder.png';
import closeIcon from '../../../assets/close-icon.svg';
import logo from '../../../assets/logo-white.svg';

import showcases from './showCases.json';

function ShowcaseModal({ history }) {
  const [isVisible, setVisability] = useState(false);

  const closeModal = () => {
    setVisability(false);
    setTimeout(() => { history.push('/'); }, 1000);
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Escape') {
      closeModal();
    }
  };

  useEffect(() => {
    setTimeout(() => { setVisability(true); }, 10);
    const eHandler = window.addEventListener('keyup', handleKeyUp); //eslint-disable-line
    return () => {
      window.removeEventListener('keyup', eHandler); //eslint-disable-line
    };
  }, []);

  const params = useParams();
  const showCase = showcases[params.showcaseSlug];


  return (
    <div className={classNames('showcasemodal', { 's-visible': isVisible })}>
      <Container className="showcasemodal__header">
        <Row>
          <Col xs={8}>
            <img src={logo} alt="jvn-logo" className="showcasemodal__header__logo" />
          </Col>
          <Col xs={4} className="text-right">
            <button type="button" onClick={closeModal} className="showcasemodal__header__close">
              <img src={closeIcon} alt="X" />
            </button>
          </Col>
        </Row>
      </Container>
      <Container>
        <Col xs={12} md={{ offset: 1, span: 10 }}>
          <div className="showcasemodal__player">
            <img src={placeholder} className="showcasemodal__player__placeholder" alt="" />
            <iframe title="player" className="showcasemodal__player__yt-frame" src={showCase.videoUrl} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </div>
        </Col>

        <div className="showcasemodal__project">
          <div className="showcasemodal__project__content">
            <span className="showcasemodal__project__company">{showCase.name}</span>
            <span className="showcasemodal__project__project">{showCase.description}</span>
          </div>
        </div>

        <div className="showcasemodal__collage mb-5 pb-4">
          <Row>
            {showCase.images.map((img) => (
              <Col xs={img.colSize} className={classNames('showcasemodal__collage__item', img.className)}>
                <div className="showcasemodal__collage__item__img" style={{ backgroundImage: `url(${img.url})` }}>
                  {!img.format && <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAYAAAFMLZykAAAABGdBTUEAALGPC/xhBQAAAENJREFUKBVjvHHjxn8GIGACESDAiFsEogBJCUwArhmnANxUmAp0GsMIdAWUm0DQCsoVEHQkuq/Q+QSdgK4BnT/wBgAAe3IY0V/brwkAAAAASUVORK5CYII=" alt="" className="portfoliotile__placeholder" />}
                  {img.format && <img src={`/placeholder-${img.format}.png`} alt="" className="portfoliotile__placeholder" />}
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  );
}

ShowcaseModal.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(ShowcaseModal);
