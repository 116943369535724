import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './WelcomeContainer.scss';

function WelcomeContainer() {
  // eslint-disable jsx-a11y/media-has-caption
  return (
    <div className="welcomecontainer">
      <Container>
        <Row>
          <Col xs={12} className="my-5 py-3">
            <h2>
                Wij produceren videoproducties en live uitzendingen
                voor merken, bedrijven en bureaus.
            </h2>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default WelcomeContainer;
