import React from 'react';
import './BarsContainer.scss';


function BarsContainer() {
  // eslint-disable jsx-a11y/media-has-caption
  return (
    <div className="barscontainer">
      <span className="barscontainer__logo">
        <h1>event livestreamen?</h1>
        <img src="/bars_logo.svg" alt="" />
      </span>
    </div>
  );
}

export default BarsContainer;
