/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import Container from 'react-bootstrap/Container';

function DisclaimerPage() {
  return (
    <div className="clients">
      <Container>
        <div>
          <p className="p-smallcontent">
            <h1>Disclaimer</h1>
            <h3>Voorwaardelijkheidsverklaring</h3>
            <br />

          Alle rechten, waaronder de auteursrechten, op de inhoud van deze internetsite berusten bij Jongens van Nipkow Media. Gebruikers van website erkennen de (intellectuele) eigendomsrechten van Jongens van Nipkow Media op de inhoud, zowel in haar geheel, als gedeelten daaruit, dan wel vervat in alle aantoonbaar daarop gebaseerde en/of daaraan ontleende informatie, ongeacht of zodanige daarop gebaseerde en/of ontleende informatie (mede) het product is van een bewerking, veredeling of prestatie door een ander dan Jongens van Nipkow Media.
            <br />
            <br />
Gebruiker mag de inhoud van deze website slechts voor eigen gebruik raadplegen en gebruiken. Het is gebruiker niet toegestaan om informatie verkregen op deze website op niet-incidentele basis openbaar te maken, te kopiëren, te verveelvoudigen of te verkopen in welke vorm dan ook, daaronder begrepen het al dan niet na bewerking te integreren in netwerken of op meerdere beeldschermen te verschijnen of anderszins openbaar te maken.
            <br />
            <br />
De verwerking van de gegevens is naar beste weten en met de grootste zorg uitgevoerd. Elke vorm van auteursrechten schending zal derhalve ook meteen juridisch aangepakt worden.
            <br />
            <br />
Jongens van Nipkow Media is niet verantwoordelijk voor pagina's van externe organisaties waarnaar verwezen wordt. Jongens van Nipkow Media geeft geen enkele garantie inzake de juistheid of de volledigheid, of het bijgewerkt zijn van de informatie die op dit systeem werd geraadpleegd of die via dit systeem werd uitgewisseld.
            <br />
            <br />
Jongens van Nipkow Media kan geen garantie bieden betreffende de veiligheid van de website, noch aansprakelijk gesteld worden voor de schade die, rechtstreeks of onrechtstreeks, het gevolg is van een onderbreking in de beschikbaarheid van de site.
            <br />
            <br />
COPYRIGHT De (intellectuele) eigendomsrechten met betrekking tot de inhoud en de vormgeving van deze internetsite, waaronder begrepen de teksten, databestanden, foto's en overig (stilstaand en/of bewegend) beeldmateriaal, geluidsmateriaal, berusten bij Jongens van Nipkow Media of haar licentiegevers. De gebruiker mag de inhoud van deze internetsite slechts voor persoonlijk en niet commercieel gebruik aanwenden. Dit betekent dat al het overige gebruik niet is toegestaan, tenzij daarvoor voorafgaande schriftelijke toestemming van Jongens van Nipkow Media is verkregen.
            <br />
            <br />
Deze website is alleen bedoeld voor eigen raadpleging via normaal browserbezoek. Linken en verwijzen naar pagina's van deze internetsite op internet is in beginsel toegestaan, echter deze links dienen bezoekers rechtstreeks te leiden naar de context, waarbinnen Jongens van Nipkow Media de content aanbiedt. Video- en audiostreams mogen bijvoorbeeld alleen worden vertoond via een link naar de internetsite van Jongens van Nipkow Media. Overneming, inframing, herpublicatie, bewerking of toevoeging is niet toegestaan. Eveneens is het niet toegestaan technische beveiligingen te omzeilen of te verwijderen, of dit voor anderen mogelijk te maken. Gebruik van het logo Jongens van Nipkow Media ten behoeve van linken of verwijzen is zonder schriftelijke toestemming niet toegestaan.
          </p>
        </div>
      </Container>

    </div>
  );
}


export default DisclaimerPage;
