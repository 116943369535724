/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import Container from 'react-bootstrap/Container';
import { NavLink } from 'react-router-dom';

function CookiePage() {
  return (
    <div className="clients">
      <Container>
        <div>
          <p className="p-smallcontent">
            <h1>Cookies</h1>
            <h2>Kruimels.</h2>
            <br />

          Dit Cookie Statement beschrijft de cookies waarvan Jongens van Nipkow Media (hierna ook: “we” en “ons”) gebruik maakt via de website https://www.jongensvannipkow.nl (hierna: de “Website”) en voor welk doel deze cookies worden gebruikt.

            <br />
            <br />
            <br />
            <h4>Wat zijn cookies?</h4>

Cookies zijn kleine stukjes (tekst)informatie die bij uw bezoek aan een website worden meegestuurd aan uw browser en daarna op de harde schijf of in het geheugen van uw computer, tablet of mobiele telefoon (hierna: “Apparaat”) worden opgeslagen. De cookies die via de Website worden geplaatst, kunnen uw Apparaat of de bestanden die daarop opgeslagen staan niet beschadigen.

            <br />
            <br />
            <br />
            <h4>Welke cookies gebruiken we?</h4>

Analytics cookies
Voor het verzamelen van statistieken over het gebruik en bezoek van de Website, maakt Jongens van Nipkow Media gebruik van analyticsdiensten. Deze gegevens worden door de aanbieder van deze analyticsdiensten geanalyseerd en de resultaten hiervan worden aan Jongens van Nipkow Media verstrekt. Jongens van Nipkow Media krijgt op deze manier inzicht in hoe de Website wordt gebruikt en kan aan de hand van die gegevens de website en/of haar dienstverlening zo nodig aanpassen. Als dat wettelijk verplicht is, kan de analyticsdienst deze informatie aan derden verstrekken. Jongens van Nipkow Media maakt gebruik van de volgende derden-partij analyticsdiensten: Google Analytics.

            <br />
            <br />
            <br />
            <h4>Verwijderen cookies</h4>

De toestemming die u heeft gegeven aan Jongens van Nipkow Media voor het plaatsen en uitlezen van cookies, kunt je altijd intrekken door uw browser zo in te stellen dat deze geen cookies accepteert of door alle in uw browser reeds geplaatste cookies te verwijderen. Raadpleeg de helpfunctie van je browser om te zien op welke wijze u de cookies kunt verwijderen.

U moet er wel rekening mee houden dat het verwijderen van cookies tot gevolg kan hebben dat bepaalde onderdelen van de Website niet of niet goed meer werken.

Het weigeren en verwijderen van cookies heeft alleen gevolgen voor de computer en de browser waarop u deze handeling uitvoert. Als u gebruik maakt van meerdere computers en/of browsers moet je de bovengenoemde handeling(en) voor elke computer en/of browser herhalen.

            <br />
            <br />
            <br />
            <h4>Privacy Statement</h4>

De mogelijkheid bestaat dat de informatie die door middel van een cookie verzameld wordt persoonsgegevens bevat. Als dat het geval is, is op de verwerking van deze persoonsgegevens tevens het Privacy Statement van Jongens van Nipkow Media van toepassing. Het
            {' '}
            <NavLink to="/privacy">Privacy Statement</NavLink>
            {' '}
vind u hier.

            <br />
            <br />
            <br />
            <h4>Wijzigingen</h4>

Dit Cookie Statement kan te allen tijden worden gewijzigd.

            <br />
            <br />
            <br />
            <h4>Vragen</h4>

Mocht u nog vragen hebben over dit Cookie Statement, stuur dan een bericht naar info@jongensvannipkow.nl
            {' '}

          </p>
        </div>
      </Container>

    </div>
  );
}


export default CookiePage;
