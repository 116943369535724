import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import './PortfolioContainer.scss';
import PortfolioTile from '../PortfolioTile/PortfolioTile';

function PortfolioContainer() {
  return (
    <div className="portfoliocontainer">
      <Container>
        <h1 className="text-white mb-4">Showcase</h1>
        <Row>
          <PortfolioTile company="Auto Wüst" project="TV Commercial" poster="/cases/Wust/Wust-poster.png" video="/cases/Wust/Wust.mp4" slug="/showcase/wust" />
          <PortfolioTile company="Gemeente Hoeksche Waard" project="Livestream" poster="/cases/hw/hw-poster.jpg" video="/cases/hw/hw-video.mp4" slug="/showcase/gemeente-hw" />
          <PortfolioTile company="Jonkman Opleidingen" project="Bedrijfsfilm" poster="/cases/jonkman/jonkman.jpg" video="/cases/jonkman/jonkman.mp4" slug="/showcase/jonkman" />

          <PortfolioTile company="Inprevo" project="Webinar" poster="/cases/inprevo/inprevo.jpg" video="/cases/inprevo/inprevo.mp4" slug="/showcase/inprevo" />
          <PortfolioTile company="Green Impact Award" project="Meercamera registratie" poster="/cases/impact/impact.jpg" video="/cases/impact/impact.mp4" slug="/showcase/green-impact" />
          <PortfolioTile company="ADE" project="Afsheen" poster="/cases/ADE/ADE-poster.png" video="/cases/ADE/ADE.mp4" slug="/showcase/ade" />

          <PortfolioTile company="Salet" project="TV Commercial" poster="/cases/Salet/Salet-poster.png" video="/cases/Salet/Salet.mp4" slug="/showcase/salet" />
          <PortfolioTile company="Hogeschool Inholland" project="Livestream" poster="/cases/inholland-dh/inholland2.png" video="/cases/inholland-dh/inholland2.mp4" slug="/showcase/inholland-denhaag" />
          <PortfolioTile company="ENG" project="" poster="/cases/ENG/eng.jpg" video="/cases/ENG/eng.mp4" slug="/showcase/eng" />

        </Row>
      </Container>
    </div>
  );
}

export default PortfolioContainer;
