import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import './Contact.scss';
import Col from 'react-bootstrap/Col';
import { NavLink } from 'react-router-dom';
import BarsContainer from '../BarsContainer/BarsContainer';
import waLogo from '../../assets/logo-wa.svg';
import igLogo from '../../assets/logo-ig.svg';

function Contact() {
  return (
    <>
      <div className="contact pt-5">
        <Container>
          <Col xs={12} className="contact__instagram mb-2">
            <a href="https://instagram.com/nipkowbroadcast" target="_blank" rel="noopener noreferrer" className="contact__instagram__logo">
              <img src={igLogo} alt="" />
              @nipkowbroadcast
            </a>
            <Row>
              <Col xs={4} sm={2} className="contact__instagram__post">
                <div className="contact__instagram__post__img" style={{ backgroundImage: 'url(/insta1.jpg)' }}>
                  <img src="/placeholder-1x1.png" alt="" />
                </div>
              </Col>
              <Col xs={4} sm={2} className="contact__instagram__post">
                <div className="contact__instagram__post__img" style={{ backgroundImage: 'url(/insta2.jpg)' }}>
                  <img src="/placeholder-1x1.png" alt="" />
                </div>
              </Col>
              <Col xs={4} sm={2} className="contact__instagram__post">
                <div className="contact__instagram__post__img" style={{ backgroundImage: 'url(/insta3.jpg)' }}>
                  <img src="/placeholder-1x1.png" alt="" />
                </div>
              </Col>
              <Col xs={4} sm={2} className="contact__instagram__post mt-3 mt-sm-0">
                <div className="contact__instagram__post__img" style={{ backgroundImage: 'url(/insta4.jpg)' }}>
                  <img src="/placeholder-1x1.png" alt="" />
                </div>
              </Col>
              <Col xs={4} sm={2} className="contact__instagram__post mt-3 mt-sm-0">
                <div className="contact__instagram__post__img" style={{ backgroundImage: 'url(/insta5.jpg)' }}>
                  <img src="/placeholder-1x1.png" alt="" />
                </div>
              </Col>
              <Col xs={4} sm={2} className="contact__instagram__post mt-3 mt-sm-0">
                <div className="contact__instagram__post__img" style={{ backgroundImage: 'url(/insta6.jpg)' }}>
                  <img src="/placeholder-1x1.png" alt="" />
                </div>
              </Col>
            </Row>
          </Col>
        </Container>
      </div>

      <BarsContainer />

      <div className="contact p-0">
        <Container className="contact--bg">


          <Row>
            <Col xs={12} sm={5}>
              <h1 className="text--white mb-4">Contact</h1>

              <p>
                <strong>Jongens van Nipkow Media</strong>
                <br />
                <small>
KvK: 68288662
                </small>
              </p>
              <Row>
                <Col xs={6} className="mt-2 mb-4">
                  <strong>e-mail:</strong>
                  <br />
              info@jongensvannipkow.nl
                </Col>

              </Row>

              <a href="https://wa.me/31625241875" target="_blank" rel="noopener noreferrer" className="contact__whatsapp-link">
                <img src={waLogo} className="contact__whatsapp-link__logo" alt="" />
              stuur een bericht via whatsapp
              </a>
            </Col>
            {/* <Col xs={{ span: 8, offset: 2 }} sm={7}>

              <img src="/img/gi.jpg" className="w-100" alt="" />
            </Col> */}
          </Row>
        </Container>
      </div>

      <div className="footer">
        <Container>
          <Row>
            <Col xs={12} sm={4} className="text-left">
              &copy; copyright 2023
            </Col>
            <Col xs={12} sm={8}>

              <NavLink to="/privacy">privacybeleid</NavLink>
              <NavLink to="/disclaimer">disclaimer</NavLink>
              <NavLink to="/cookies">cookies</NavLink>
              <NavLink to="/voorwaarden">algemene voorwaarden</NavLink>
            </Col>

          </Row>
        </Container>
      </div>
    </>
  );
}

export default Contact;
